/* src/Chatbot.css */

/* Define colors */
:root {
    --primary-color: #2C3E50; /* Deep Blue */
    --secondary-color: #F1C40F; /* Soft Gold */
    --accent-color: #1ABC9C; /* Teal */
    --background-color: #ECF0F1; /* Light Gray */
    --text-color: #34495E; /* Dark Slate Gray */
    --response-background-color: #E0F7F4; /* Light Teal */
}

/* Font face declaration */
@font-face {
    font-family: 'PlaywriteESDeco';
    src: url('./fonts/PlaywriteESDeco-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

html, body {
    height: 100%;
    margin: 0;
}

.app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-header {
    padding: 0;
    background: black;
    text-align: center;
    width: 100%;
}

.auth-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    /* Removed border-radius for consistency */
}

.auth-info p {
    margin: 0;
    margin-right: 10px;
}

.auth-info button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #0078d4;
    color: white;
    cursor: pointer;
}

.auth-info button:hover {
    background-color: #005a9e;
}

.top-header {
    text-align: left;
}

.chat-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    text-align: left;
    background-color: #fff;
    border: 1px solid #48cdfe;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%; /* Default width */
    max-height: 100vh; /* Set max height to viewport height */
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease, max-height 0.3s ease; /* Smooth transition */
    overflow: hidden; /* Prevent overflow issues */
}
/* Chat Title Bar */
.chat-title-bar {
    font-family: 'PlaywriteESDeco';


}
.chat-toggle-button {
    width: 100%;
    background: var(--primary-color);
    color: white;
    padding: 10px;
}

.chat-toggle-button {
    animation: gradientAnimation 4s ease infinite;
    background: linear-gradient(135deg, #06598d, #ff0000);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    margin: 0;
    text-align: center;
    width: 100%;
}

.chat-popup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 25px;
    padding: 10px; /* Add padding inside the chat popup */
    position: relative;
    background-color: #fff;
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 0 0 8px 8px;
    width: 100%; /* Ensure it takes the width of the container */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    flex-basis: 100%; /* Allow the chat-popup to grow */
}



/* Loading Overlay */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 54, 54, 0.7);
    display: none;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
}

.loading-overlay.visible {
    display: flex;
}

.loading-indicator {
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
    color: white;
    background-color: transparent;
    animation: blinker 2s linear infinite;
    margin-bottom: 0px;
}

/* Response Container */
.response-container {
    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    padding: 5px;
    background-color: var(--background-color);
    box-sizing: border-box;
    height: 100%; /* Adjust to ensure the container height is 100% */
    width: 100%;
}

.response-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.response-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Textarea Styling */
textarea {
    padding: 10px;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    font-size: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: both;
    overflow: auto;
    min-height: 50px;
    max-height: calc(20 * 1.5em);
}
.large-button {
    font-size: 2rem;       /* Larger text size */
    padding: 20px 40px;    /* Increased padding */
    margin: 10px;          /* Margin for spacing */
    border-radius: 10px;   /* Rounded corners */
    cursor: pointer;       /* Pointer cursor for better UX */
}
.mute-button {
    font-size: 1rem;
    padding: 10px 20px;
    margin: 10px auto; /* Center the button in the chatbox */
    display: block;    /* Ensure it’s visible only when needed */
}
.button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 10px;
    z-index: 1;
}
@keyframes heartbeat {
    0%, 100% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(1.05);
    }
}

.heartbeat-chat-bar {
    animation: heartbeat 20s 2; /* 2-second duration, infinite loop */
    animation-timing-function: ease; /* Smooth heartbeat effect */
}

.speech-controls-container {
    position: relative;
}



/* Button Container */
.button-container {
    display: flex;
    justify-content: space-between;
}

/* Button Styling */
button[type="submit"]
{
    background-color: #48cdfe;
    color: black;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 15px;
    text-transform: uppercase;
}

button[type="submit"]:hover, {
    background-color: #3bb0da;
}
button.justbearcat-clear {
    background-color: pink;
    color: black;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box;
    width: 25%;
    height: 50px;
    font-size: 15px;
    text-transform: uppercase;
}
button.mute-button {
    background-color: silver;
    color: black;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 15px;
    text-transform: uppercase;
    opacity: 95%;
}
/* Chat Messages */
.chat {
    margin: 10px 0;
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 4px;
}

.user {
    background-color: var(--background-color);
    padding: 5px;
    border-radius: 4px;
}
.filebaby {
    background-color: var(--response-background-color);
    padding: 5px;
    border-radius: 4px;
}

.filebaby p {
    margin: 0;
    padding: 5px 0;
}

/* Keyframes for Animations */
@keyframes blinker {
    50% {
        opacity: 0;
    }
}



/* Indicators */
.listening-indicator,
.speaking-indicator {
    padding: 5px 10px;
    background-color: #ffeb3b;
    border-radius: 4px;
    margin: 10px;
    text-align: center;
}
.speech-controls {
    display: flex;
    gap: 10px;
    background-color: #587a91;

}
.speech-button:disabled {
    background-color: #d3d3d3; /* Light grey color to indicate it's disabled */
    color: #a0a0a0; /* Dim the text color to indicate it's inactive */
    cursor: not-allowed; /* Change cursor to indicate it can't be interacted with */
    transform: none; /* Remove scaling effect */
    box-shadow: none; /* Remove any shadow to indicate it's inactive */
}

.speech-button {
    background-color: #2c3e50; /* Dark Blue */
    color: white; /* Soft Gold for text/icon */
    border: none;
    padding: 10px 15px;
    font-size: 16px;

    align-items: center;
    gap: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: block;
    margin-left:auto;
    margin-right:auto;
    width: 50%;
}


.speech-button:hover {
    background-color: #1abc9c; /* Teal on hover */
    transform: scale(1.05);
}

.speech-button:active {
    transform: scale(0.95);
}

.speech-button svg {
    font-size: 1.2em; /* Size of the icon */
}

.speech-controls-container {
    position: relative;
    display: inline-block;
}

.speech-controls {
    display: flex;
    gap: 10px;
}

.button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px; /* Match button styling */
    pointer-events: none; /* Prevent overlay from blocking mouse events */
    z-index: 10; /* Ensure it appears above the buttons */
}
.resource-links li {
    list-style: none;
    padding:none;
}
.resource-links {
    color: white;
}
.overlay-text {
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-align: center;

}

/* Notification Styling */
.notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    z-index: 1001; /* Above other elements */
}

.notification.error {
    background-color: red;
}

.notification.downloading {
    background-color: blue;
}

/* Toggle Switch Styles */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-right: 8px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4caf50;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.toggle-label {
    margin-right: 16px;
    font-size: 14px;
    vertical-align: middle;
}

/* Container for Toggles */
.toggles-container {
    display: none;
    align-items: center;
    gap: 16px;
}

/* Hero Image Styling */
.hero-img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    margin: 0;
    padding:0;
}

/* Responsive Design */
@media (min-width: 768px) {
    .loading-indicator {
        font-size: 1.2em;
    }
    .chat-popup {
        width: 100%; /* Ensure popup matches the container width */
    }
}
