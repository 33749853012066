

.tags {
    font-size: 1rem;
    color: #95a5a6;
}
.audio-player {
    margin: 1.5em 0;
    text-align: center;
}

audio {
    width: 100%;
    max-width: 600px;
    outline: none;
}
